import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";


@Injectable({
  providedIn: 'root'
})
export class DataManagerService {

  public data$:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public audioUrl$:Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) {
    this.getJSON().subscribe(data => {
      this.data$.next(data);
    });
  }


  public getJSON(): Observable<any> {
    return this.http.get("./assets/data.json");
  }
}
