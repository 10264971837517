import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {

  private platform: any;

  @Input('lng') lng;
  @Input('lat') lat;
  @ViewChild("map", null)
  public mapElement: ElementRef;
  map = null;
  markerPos = null;

  public constructor() {
    // @ts-ignore
    this.platform = new H.service.Platform({
      "app_id": "dwnZZhxqo2586OkeFB4p",
      "apikey": "jvUISJCetmI2jDhGqorUA4QMY1Xuxv0dHucQxCGIm1k"
    });
  }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    // let defaultLayers = this.platform.createDefaultLayers();
    // // @ts-ignore
    // let map = new H.Map(
    //   this.mapElement.nativeElement,
    //   defaultLayers.normal.map,
    //   {
    //     zoom: 10,
    //     center: { lat: this.lat, lng: this.lng }
    //   }
    // );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['lat']['currentValue'] && changes['lng']['currentValue']) {
      let current_lat = changes['lat']['currentValue'].toFixed(5);
      let current_lng = changes['lng']['currentValue'].toFixed(5);
      console.log({lat: changes['lat']['currentValue'], lng: changes['lng']['currentValue']});
      if (this.map === null) {
        let defaultLayers = this.platform.createDefaultLayers();
        // @ts-ignore
        this.map = new H.Map(
          this.mapElement.nativeElement,
          defaultLayers.vector.normal.map,
          {
            zoom: 15,
            center: {lat: current_lat, lng: current_lng}
            // center: { lat: 50.77, lng: 6.1 }
          }
        );
        // @ts-ignore
        let ui = H.ui.UI.createDefault(this.map, defaultLayers, 'de-DE');
      } else {

// Create a marker using the previously instantiated icon:
        if (this.markerPos === null) {
          // @ts-ignore
          // let svgicon = new H.map.Icon(svgMarkup);
          // var icon = new H.map.Icon('https://assets/img/marker.png');

          // @ts-ignore
          // this.markerPos = new H.map.Marker({lat: current_lat, lng: current_lng}, {icon: icon});
          this.markerPos = new H.map.Marker({lat: current_lat, lng: current_lng});
// Add the marker to the map:
          this.map.addObject(this.markerPos);

        }
        if (this.map !== null) {
          this.markerPos.setGeometry({lat: current_lat, lng: current_lng});
          this.map.setCenter({lat: current_lat, lng: current_lng}, true)
        }
      }


      // Create a marker icon from an image URL:
      // let icon = new H.map.Icon('graphics/markerHouse.png');


    }
  }

}
