import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {delay, first, switchMap} from 'rxjs/operators';
import {DataManagerService} from "../../data-manager.service";
import {Subject} from "rxjs";
import {Sight} from "../../interfaces/sight";
import {find} from 'lodash';
import { faMapMarkedAlt } from '@fortawesome/pro-light-svg-icons';
import { faPlay } from '@fortawesome/pro-light-svg-icons';


@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.scss']
})


export class RoutingComponent implements OnInit {

  faMapMarkedAlt = faMapMarkedAlt;
  faPlayCircle= faPlay;

  usertype$;
  sightsInSightIds$: Subject<any> = new Subject<any>();
  lat: number;
  lng: number;
  refreshIntervallinSec = 1;
  lastPosition = [0, 0];
  activeLanguage: string = "de";
  player;

// Material Style Advance Audio Player Playlist
  audiodata = [
    {
      title: 'Digital Church',
      link: '/assets/audio/digital_church.mp3'
    },
    {
      title: 'Digital Church 2',
      link: '/assets/audio/digital_church2.mp3'
    },
    {
      title: 'Ludwig Forum',
      link: '/assets/audio/ludwig_forum.mp3'
    },
  ];

  audioindex = 0;

  audioSrc = "";

  hidePlayer = true;
  playStatePaused = true;

  sightsInSight$: Subject<Array<Sight>> = new Subject<any>();


  constructor(private route: ActivatedRoute,
              private router: Router,
              private dataManager: DataManagerService
  ) {

  }

  ngOnInit() {
    this.usertype$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        return params.get('usertype');
      })
    );

    this.usertype$.subscribe(
      (data) => console.log(data)
    );

    this.dataManager.data$
      .pipe(
        first(),
        delay(500)
      )
      .subscribe
      (() => {
        this.updateGeo();
      });

    setInterval(() => {
      this.updateGeo();
    }, this.refreshIntervallinSec * 1000);

    this.sightsInSightIds$.subscribe(data => {
      let sightsSorted = [];
      for (let sightIdObject of data) {
        let id = sightIdObject['id'];
        let data = this.dataManager.data$.getValue();
        let nextSight = find(data, {id: id});
        nextSight.distance = sightIdObject['distance'];
        sightsSorted.push(nextSight);
      }
      this.sightsInSight$.next(sightsSorted);
    });

    this.sightsInSight$.subscribe((data)=>{

    });

    // setInterval(() => {
    //   console.log("interval");
    //
    //   let url = this.audiodata[this.audioindex]['link'];
    //   this.audioindex++;
    //   if (this.audioindex == 3) {
    //     this.audioindex = 0;
    //   }
    //   this.hidePlayer = false;
    //   this.audioSrc = url;
    // }, 5000);


  }

  play(file){
    let url = "/assets/audio/"+file;
    console.log(url);
    this.hidePlayer = false;
    this.playStatePaused = false;
    this.audioSrc = url;
  }

  initPlayer(event) {
    this.player = event;

    this.player.on("canplay", () => {
      if (!this.playStatePaused) {
        this.player.play();
      }
    });

    this.player.on("play", () => {
      this.playStatePaused = false;
    });
    this.player.on("pause", () => {
      this.playStatePaused = true;
    });
  }

  played(event) {
    console.log(event);
  }


  updateGeo() {
    this.getLocation();
    let currentPosition = [this.lat, this.lng];
    this.sightsInSightIds$.next(
      // @ts-ignore
      next_sights(
        currentPosition,
        this.lastPosition,
        this.dataManager.data$.getValue(),
        this.refreshIntervallinSec)
    );
    this.lastPosition = currentPosition;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
          if (position) {
            // console.log("Latitude: " + position.coords.latitude +
            //   "Longitude: " + position.coords.longitude);
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
          }
        },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getImageUrl(filename) {
    // console.log( 'assets/img/'+filename);
    return 'assets/img/' + filename;
  }


}
