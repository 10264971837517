import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';


import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AppRoutingModule} from "./app-routing/app-routing.module";
import {StartComponent} from './components/start/start.component';
import {RoutingComponent} from './components/routing/routing.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {DataManagerService} from "./data-manager.service";
import { MapComponent } from './components/map/map.component';
import {MatChipsModule} from "@angular/material/chips";
import { SafePipe } from './safe.pipe';
import {PlyrModule} from "ngx-plyr";
import {MatExpansionModule, MatExpansionPanel} from "@angular/material/expansion";

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    RoutingComponent,
    MapComponent,
    SafePipe,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRoutingModule,

    FontAwesomeModule,

    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatChipsModule,
    MatExpansionModule,

    PlyrModule

],
  providers: [
    DataManagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
