import { Component, OnInit } from '@angular/core';
import { faBooks } from '@fortawesome/pro-light-svg-icons';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons';


@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  faShort = faFileAlt;
  faLong = faBooks ;
  activeLanguage = "de";

  constructor() { }

  ngOnInit() {
  }

  switchLanguage(selectedLanguage){
    this.activeLanguage=selectedLanguage;
  }

  getFlag(language){
    let filename = 'assets/flags/4x3/'+language+".svg";
    if (language == "op"){
      filename = 'assets/flags/4x3/'+language+".jpg";
    }
    return filename;
  }

}
