import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StartComponent} from "../components/start/start.component";
import {RoutingComponent} from "../components/routing/routing.component";

const routes: Routes = [
  {
    path: 'start',
    component: StartComponent,
  },
  {
    path: 'routing/:usertype',
    component: RoutingComponent,
  },
  {
    path: '',
    redirectTo: '/start',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/start'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule {
}
